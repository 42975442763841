import { memo } from 'react'
import LOGO from 'src/images/logo.png'
const Header = () => {
  return (
    <header>
      <img src={LOGO} alt="orders.co logo" />
      <p id="siteUrl"></p>
    </header>
  )
}

export default memo(Header)
