import {useEffect, useState} from "react";
import OrderService from "src/service/orderService";


const useProviderConfig = (orderEnv) => {
  const [providerGroup, setProviderGroup] = useState({});

  useEffect(() => {
   const fetchProvider = async () => {
     try {
       const providers = await OrderService.getProviderConfig(orderEnv);
       setProviderGroup(providers?.data?.data?.groups);
     } catch (e) {
       console.log('Error getting provider data', e);
     }
   }
    fetchProvider().then();
  }, [orderEnv]);

  return {
    providerGroup
  }
}

export default useProviderConfig;