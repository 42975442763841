import Header from "src/components/Header/Header";
import { Order } from "src/components/Order/Order";
import { useParams } from 'react-router-dom';


const Layout = () => {
  const { env, businessId, orderId } = useParams();
  return (
    <>
      <Header env={env} />
      <main>
        <Order env={env} businessId={businessId} orderId={orderId} />
      </main>
    </>
  )
}
export default Layout