import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_BASE_URL

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use((config) => {
  return config
})

axiosInstance.interceptors.response.use((config) => config, async error => {
  try {
    if (error.config) {
      return Promise.reject((error.message) || 'Something went wrong!')
    }
  } catch (error) {
    console.log(error)
    return Promise.reject((error.response && error.response.data) || 'Something went wrong!')
  }
})

export default axiosInstance;
